exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shopping-cart-js": () => import("./../../../src/pages/shopping-cart.js" /* webpackChunkName: "component---src-pages-shopping-cart-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-artist-template-js": () => import("./../../../src/templates/artist-template.js" /* webpackChunkName: "component---src-templates-artist-template-js" */),
  "component---src-templates-class-template-js": () => import("./../../../src/templates/class-template.js" /* webpackChunkName: "component---src-templates-class-template-js" */),
  "component---src-templates-classes-template-js": () => import("./../../../src/templates/classes-template.js" /* webpackChunkName: "component---src-templates-classes-template-js" */),
  "component---src-templates-exhibition-template-js": () => import("./../../../src/templates/exhibition-template.js" /* webpackChunkName: "component---src-templates-exhibition-template-js" */),
  "component---src-templates-exhibitions-template-js": () => import("./../../../src/templates/exhibitions-template.js" /* webpackChunkName: "component---src-templates-exhibitions-template-js" */),
  "component---src-templates-shop-category-template-js": () => import("./../../../src/templates/shop-category-template.js" /* webpackChunkName: "component---src-templates-shop-category-template-js" */),
  "component---src-templates-shop-product-template-js": () => import("./../../../src/templates/shop-product-template.js" /* webpackChunkName: "component---src-templates-shop-product-template-js" */),
  "component---src-templates-shop-template-js": () => import("./../../../src/templates/shop-template.js" /* webpackChunkName: "component---src-templates-shop-template-js" */)
}

